<template>
  <div>
    <v-tooltip v-if="!readonly" left color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon
            :color="
              section === 1 && $vuetify.theme.dark === false
                ? 'white'
                : 'secondary'
            "
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Outcome</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="1200"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card height="900" flat>
        <v-card-title class="text-h5 secondary--text">
          Other Outcome Details<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-0 pt-4">
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tabs-slider light />

            <v-tab :key="1" :href="'#tab-1'">
              Outcome Information
            </v-tab>

            <v-tab :key="2" :href="'#tab-2'">
              T-2 Researchers
            </v-tab>

            <v-tab :key="3" :href="'#tab-3'">
              T-2 Trainees
            </v-tab>

            <v-tab :key="4" :href="'#tab-4'">
              Non T-2 Participants
            </v-tab>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text class="py-0">
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-tabs-items v-model="tab">
              <!--General Information-->
              <v-tab-item :key="1" value="tab-1">
                <v-container class="py-0" fluid>
                  <v-card flat>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <span class="subtitle-1 font-weight-bold black--text"
                          >You will not be able to submit the record without
                          completing required fields. Fields that are
                          non-required for DOP purposes are required for PI's
                          annual RPPR thus completion is recommended.</span
                        >
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <span class="caption error--text"
                          >* Required Fields</span
                        >
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Author of Record-->
                      <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="id_user"
                          :items="participantsStore.researchersValueList"
                          item-text="name_reverse"
                          item-value="id"
                          label="Author Of Record *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Outcome Type-->
                      <v-col class="px-0" cols="12" sm="6">
                        <v-autocomplete
                          v-model="type"
                          :items="valueListsStore.outcomeTypeList"
                          label="Type *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Other Product Type-->
                      <v-col v-if="type === 'Other Product'" cols="12" sm="12">
                        <v-autocomplete
                          v-model="product_type"
                          :items="valueListsStore.outcomeProductTypeList"
                          label="Other Product Type"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Title-->
                      <v-col
                        v-if="
                          type === 'Invention' ||
                            type === 'License' ||
                            type === 'Technologies and Techniques'
                        "
                        cols="12"
                        sm="12"
                      >
                        <v-text-field
                          v-model="title"
                          label="Title *"
                          placeholder=" "
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Description (Other Product only)-->
                      <v-col v-if="type === 'Other Product'" cols="12" sm="12">
                        <v-textarea
                          v-model="description"
                          label="Description of product and how it is being shared"
                          placeholder=" "
                          grow
                          rows="2"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Description (Invention, Technology & Techniques, Website only)-->
                      <v-col
                        v-if="
                          type === 'Invention' ||
                            type === 'Technologies and Techniques' ||
                            type === 'Website'
                        "
                        cols="12"
                        sm="12"
                      >
                        <v-textarea
                          v-model="description"
                          label="Description"
                          placeholder=" "
                          grow
                          rows="2"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Inventors-->
                      <v-col v-if="type === 'Invention'" cols="12" sm="12">
                        <v-textarea
                          v-model="inventor"
                          label="Inventor(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on documentation"
                          placeholder=" "
                          no-resize
                          rows="1"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Assignee-->
                      <v-col v-if="type === 'License'" cols="12" sm="12">
                        <v-textarea
                          v-model="license_assignee"
                          label="License assignee(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on documentation"
                          placeholder=" "
                          no-resize
                          rows="1"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="type" dense>
                      <!--T2 Aaward Support-->
                      <v-col cols="12" sm="4">
                        <v-autocomplete
                          v-model="t2_award_support"
                          :items="valueListsStore.yesNo"
                          :label="
                            type === 'Technologies and Techniques'
                              ? 'Was technology/technique based on work supported by this Track-2 award?'
                              : 'Was ' +
                                type +
                                ' based on work supported by this Track-2 award?'
                          "
                          item-text="value"
                          item-value="value"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="t2_award_support === 'No' && type" dense>
                      <!--T2 Aaward Description-->
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="t2_award_description"
                          :label="
                            type === 'Technologies and Techniques'
                              ? 'Provide brief justification for including this technology/technique'
                              : 'Provide brief justification for including this ' +
                                type
                          "
                          placeholder=" "
                          grow
                          rows="2"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Application Date Month-->
                      <v-col v-if="type === 'License'" cols="6" sm="6" md="2">
                        <v-autocomplete
                          v-model="date_application_month"
                          :items="valueListsStore.monthAbbreviations"
                          item-text="value"
                          item-value="value"
                          label="Application Date - Month"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Application Date Day-->
                      <v-col v-if="type === 'License'" cols="6" sm="6" md="2">
                        <v-autocomplete
                          v-model="date_application_day"
                          :items="valueListsStore.days"
                          label="Application Date - Day"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Application Date Year-->
                      <v-col v-if="type === 'License'" cols="6" sm="6" md="2">
                        <v-autocomplete
                          v-model="date_application_year"
                          :items="valueListsStore.projectYearList"
                          item-text="value"
                          item-value="value"
                          label="Application Date - Year"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Issued Date Month-->
                      <v-col
                        v-if="type === 'License' && flag_status === 'Licensed'"
                        cols="6"
                        sm="6"
                        md="2"
                      >
                        <v-autocomplete
                          v-model="date_issued_month"
                          :items="valueListsStore.monthAbbreviations"
                          item-text="value"
                          item-value="value"
                          label="Issued Date - Month"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Issued Date Day-->
                      <v-col
                        v-if="type === 'License' && flag_status === 'Licensed'"
                        cols="6"
                        sm="6"
                        md="2"
                      >
                        <v-autocomplete
                          v-model="date_issued_day"
                          :items="valueListsStore.days"
                          label="Issued Date - Day"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Issued Date Year-->
                      <v-col
                        v-if="type === 'License' && flag_status === 'Licensed'"
                        cols="6"
                        sm="6"
                        md="2"
                      >
                        <v-autocomplete
                          v-model="date_issued_year"
                          :items="valueListsStore.projectYearList"
                          item-text="value"
                          item-value="value"
                          label="Issued Date - Year"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--URL-->
                      <v-col v-if="type === 'Website'" cols="12" sm="12">
                        <v-text-field
                          v-model="url"
                          label="URL"
                          placeholder=" "
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--URL Type-->
                      <v-col v-if="url" cols="12" sm="12">
                        <v-text-field
                          v-model="url_type"
                          label="Title"
                          placeholder=" "
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Status-->
                      <v-col v-if="type === 'License'" cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-model="flag_status"
                          :items="valueListsStore.outcomeStatusList"
                          label="Status"
                          item-text="name"
                          item-value="name"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-container>
              </v-tab-item>

              <!--T-2 Researchers-->
              <v-tab-item :key="2" value="tab-2">
                <participants-selector
                  :user-type="1"
                  :section="5"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>

              <!--T-2 Trainees-->
              <v-tab-item :key="3" value="tab-3">
                <participants-selector
                  :user-type="2"
                  :section="5"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>

              <!--Non T-2 Participants-->
              <v-tab-item :key="4" value="tab-4">
                <participants-selector
                  :user-type="3"
                  :section="5"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-card-actions class="mx-4 pb-4">
          <v-row dense>
            <v-col
              v-if="tab === 'tab-2' || tab === 'tab-3' || tab === 'tab-4'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                color="red"
                dark
                outlined
                @click="previousTab()"
              >
                <v-icon small left> fal fa-arrow-left </v-icon>Back
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col v-if="tab === 'tab-4' && !readonly" cols="3" sm="3">
              <v-btn block rounded color="primary" @click="saveEntry()">
                <v-icon small left> fal fa-check </v-icon>Submit
              </v-btn>
            </v-col>

            <v-col
              v-if="tab === 'tab-1' || tab === 'tab-2' || tab === 'tab-3'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                class="green darken-2"
                dark
                @click="nextTab()"
              >
                Next
                <v-icon small right>
                  fal fa-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import ParticipantsSelector from "@/components/ParticipantsSelector";

const { mapFields: mapEntryFields } = createHelpers({
  getterType: "outcomesStore/getEntryField",
  mutationType: "outcomesStore/updateEntryField",
});

export default {
  name: "Outcome",
  components: {
    ParticipantsSelector,
  },
  mixins: [mixin],
  props: {
    section: Number,
    readonly: Boolean,
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      yearStart: 0,
      rules: {
        not_empty: [(v) => !!v || ""],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      participantsStore: (state) => state.participantsStore,
      outcomesStore: (state) => state.outcomesStore,
      valueListsStore: (state) => state.valueListsStore,
    }),
    ...mapEntryFields([
      "show",
      "valid",
      "mode",
      "view",
      "id",

      "tab",

      "id_user",
      "id_project",
      "t2_award_support",
      "t2_award_description",
      "other_product_description",
      "type",
      "product_type",
      "title",
      "inventor",
      "description",
      "url",
      "url_type",
      "license_assignee",
      "date_issued",
      "date_application",
      "date_application_month",
      "date_application_day",
      "date_application_year",
      "date_issued_month",
      "date_issued_day",
      "date_issued_year",
      "flag_show",
      "flag_status",

      "participants",
      "participants_selected",
      "non_t2_participants",
      "non_t2",
    ]),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const activeProject = this.$store.getters["usersStore/getActiveProject"];

      this.yearStart = Number(this.$_formatDateYear(activeProject.date_start));
    },

    async newEntry() {
      this.$store.dispatch("outcomesStore/resetEntry");

      this.$store.dispatch("participantsStore/resetEntry");

      await this.$_loadValueLists(15);

      const activeProject = this.$store.getters["usersStore/getActiveProject"];
      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      const activeUserType = this.$store.getters[
        "usersStore/getActiveUserType"
      ];

      if (activeUserType === 3) {
        let newUser = {
          id: activeUser.id,
          name_reverse: activeUser.name_reverse,
        };

        await this.$store.dispatch("participantsStore/addAuthUser", newUser);
      }
      const data = {
        id: activeUser.id,
        role: "",
        name_reverse: activeUser.name_reverse,
        type: activeUserType === 1 || activeUserType === 2 ? 1 : 2,
      };

      await this.$store.dispatch("participantsStore/addParticipant", data);

      this.view = this.section;
      this.id_user = activeUser.id;
      this.id_project = activeProject.id;

      this.show = true;

      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation();
      }
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      const supervisorsSelected =
        this.$store.getters["participantsStore/getParticipantsSelected"]
          .length > 0;

      if (this.$refs.entryForm.validate() && supervisorsSelected) {
        if (this.type !== "Other Product") {
          this.product_type = "";
        }
        if (
          this.type !== "Invention" &&
          this.type !== "License" &&
          this.type !== "Technologies and Techniques"
        ) {
          this.title = "";
        }
        if (this.type !== "License") {
          this.license_assignee = "";
          this.date_application_month = "";
          this.date_application_day = "";
          this.date_application_year = "";
          this.date_issued_month = "";
          this.date_issued_day = "";
          this.date_issued_year = "";
          this.flag_status = "";
        }
        if (this.type === "License" && this.flag_status !== "Licensed") {
          this.date_issued_month = "";
          this.date_issued_day = "";
          this.date_issued_year = "";
        }
        if (this.t2_award_support === "Yes") {
          this.t2_award_description = "";
        }
        if (this.type !== "Website") {
          this.url = "";
        }
        if (!this.url) {
          this.url_type = "";
        }
        let participants_selected = this.$store.getters[
          "participantsStore/getParticipantsSelected"
        ];
        let non_t2_participants = this.$store.getters[
          "participantsStore/getNonT2"
        ];

        this.participants_selected = participants_selected;
        this.non_t2 = non_t2_participants;

        let entry = this.$store.getters["outcomesStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("outcomesStore/create", entry);
        } else {
          /* Edit */
          await this.$store.dispatch("outcomesStore/update", entry);
        }
        this.cancelEntry();
      } else {
        this.tab = "tab-1";
      }
    },

    nextTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);

      //if (tabNum === 1 && !this.$refs.entryForm.validate()) return;

      tabNum++;

      this.tab = `tab-${tabNum}`;
    },

    previousTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);
      tabNum--;

      this.tab = `tab-${tabNum}`;
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

<template>
  <v-col v-if="usersStore.activeProject" cols="12" sm="12">
    <!--Funding & Publications-->
    <v-row
      v-if="usersStore.activeUserType === 1 || usersStore.activeUserType === 2"
      dense
    >
      <v-col cols="12" sm="12" md="6">
        <fundings
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <publications
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>
    </v-row>

    <!--Patents & Presentations-->
    <v-row
      v-if="usersStore.activeUserType === 1 || usersStore.activeUserType === 2"
      dense
    >
      <v-col cols="12" sm="12" md="6">
        <patents
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <presentations
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>
    </v-row>

    <!--Trainee Publications & Presentations-->
    <v-row v-if="usersStore.activeUserType === 3" dense>
      <v-col cols="12" sm="6">
        <publications
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <presentations
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>
    </v-row>

    <!--Trainee Presentations & Fellowships-->
    <v-row v-if="usersStore.activeUserType === 3" dense>
      <v-col cols="12" sm="6">
        <fellowships
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <dissertations
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>
    </v-row>

    <!--Outcomes-->
    <v-row
      v-if="
        usersStore.activeUserType === 1 ||
          usersStore.activeUserType === 2 ||
          usersStore.activeUserType === 3
      "
      dense
    >
      <v-col cols="12" sm="12">
        <outcomes
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="1"
        />
      </v-col>
    </v-row>

    <!--Trainees-->
    <v-row
      v-if="usersStore.activeUserType === 1 || usersStore.activeUserType === 2"
      dense
    >
      <v-col cols="12" sm="12">
        <users-trainees
          :key="usersStore.activeUser.id + usersStore.activeProject.id"
          :section="9"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import Fundings from "@/components/Fundings";
import Publications from "@/components/Publications";
import Patents from "@/components/Patents";
import Presentations from "@/components/Presentations";
import Fellowships from "@/components/Fellowships";
import Outcomes from "@/components/Outcomes";
import Dissertations from "@/components/Dissertations";
import UsersTrainees from "@/components/UsersTrainees";

export default {
  name: "Dashboard",
  components: {
    Fundings,
    Publications,
    Patents,
    Presentations,
    Fellowships,
    Outcomes,
    Dissertations,
    UsersTrainees,
  },
  mixins: [mixin],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      navigationStore: (state) => state.navigationStore,
      progressStore: (state) => state.progressStore,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      /*Grab active user from store*/
      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      let activeProject = this.$store.getters["usersStore/getActiveProject"];
      let activeUserType = this.$store.getters["usersStore/getActiveUserType"];
      let authUser = this.$store.getters["usersStore/getAuthUser"];
      let adminMode = this.$store.getters["usersStore/getAdminMode"];

      let uniqueProjects = this.$_uniqueProjectList(activeUser.projects);

      /*If active user isn't set and active project isn't set or if actie user doesn't have any projects*/
      if (
        (activeUser === null && activeProject === null) ||
        activeUser.projects.length == 0
      ) {
        /*If auth user is admin, then send user to settings*/
        if (authUser.flag_admin === 1) {
          await this.$store.dispatch("navigationStore/fetchAdmin");

          window.localStorage.setItem("appMode", 2);
          this.$store.dispatch("usersStore/setAppMode", 2);

          this.$router.push({ name: "settingsProjects" });
        } else {
          /*User isn't admin, then logout user*/
          this.$router.push({ name: "logout" });
        }
      } else if (activeProject) {
        /*If active project is selected */
        if (activeProject.pivot.flag_user_type === 8) {
          this.$router.push({ name: "logout" });
          return;
        }

        /*If profile isn't filled in*/
        if (
          (activeProject.pivot.flag_check !== 1 &&
            adminMode !== 1 &&
            activeUserType === 1 &&
            authUser.flag_admin !== 1) ||
          (activeProject.pivot.flag_check !== 1 &&
            adminMode !== 1 &&
            activeUserType === 2 &&
            authUser.flag_admin !== 1) ||
          (activeProject.pivot.flag_check !== 1 &&
            adminMode !== 1 &&
            activeUserType === 3 &&
            authUser.flag_admin !== 1)
        ) {
          window.localStorage.setItem("appMode", 3);
          this.$store.dispatch("usersStore/setAppMode", 3);
          this.$router.push({ name: "profileCheck" });
        } else {
          let data = {
            user_type: activeUserType,
          };
          await this.$store.dispatch("navigationStore/fetch", data);

          window.localStorage.setItem("appMode", 1);
          this.$store.dispatch("usersStore/setAppMode", 1);

          if (
            activeUserType === 4 ||
            activeUserType === 5 ||
            activeUserType === 6 ||
            activeUserType === 7
          ) {
            /* Redirect user to dashboard */
            this.$router.push({ name: "administrator" });
          }
        }
      } else if (uniqueProjects.length > 1) {
        /*If user has more than one related project and active project isn't selected, route to project selector*/
        window.localStorage.setItem("appMode", 3);
        this.$store.dispatch("usersStore/setAppMode", 3);
        this.$router.push({ name: "projectSelector" });
      } else {
        let project = "";

        /*Only one project exists */
        if (activeUser.projects.length > 1) {
          for (let i = 0; i < activeUser.projects.length; i++) {
            if (activeUser.projects[i].flag_status === 1) {
              project = activeUser.projects[i];
            }
          }
        } else {
          project = activeUser.projects[0];
        }

        if (project.pivot.flag_user_type === 8 || !project) {
          this.$router.push({ name: "logout" });
          return;
        }

        window.localStorage.setItem("activeProject", JSON.stringify(project));
        this.$store.dispatch("usersStore/setActiveProject", project);
        activeProject = this.$store.getters["usersStore/getActiveProject"];

        window.localStorage.setItem(
          "activeUserType",
          JSON.stringify(project.pivot.flag_user_type)
        );
        this.$store.dispatch(
          "usersStore/setActiveUserType",
          project.pivot.flag_user_type
        );
        activeUserType = this.$store.getters["usersStore/getActiveUserType"];

        if (adminMode !== 1) {
          window.localStorage.setItem(
            "authUserType",
            JSON.stringify(project.pivot.flag_user_type)
          );
          this.$store.dispatch(
            "usersStore/setAuthUserType",
            project.pivot.flag_user_type
          );
          authUser = this.$store.getters["usersStore/getAuthUser"];
        }

        /*If profile isn't filled in*/
        if (
          (activeProject.pivot.flag_check !== 1 &&
            adminMode !== 1 &&
            activeUserType === 1 &&
            authUser.flag_admin !== 1) ||
          (activeProject.pivot.flag_check !== 1 &&
            adminMode !== 1 &&
            activeUserType === 2 &&
            authUser.flag_admin !== 1) ||
          (activeProject.pivot.flag_check !== 1 &&
            adminMode !== 1 &&
            activeUserType === 3 &&
            authUser.flag_admin !== 1)
        ) {
          window.localStorage.setItem("appMode", 3);
          this.$store.dispatch("usersStore/setAppMode", 3);
          this.$router.push({ name: "profileCheck" });
        } else {
          let data = {
            user_type: project.pivot.flag_user_type,
          };

          await this.$store.dispatch("navigationStore/fetch", data);

          window.localStorage.setItem("appMode", 1);
          this.$store.dispatch("usersStore/setAppMode", 1);

          if (
            project.pivot.flag_user_type === 4 ||
            project.pivot.flag_user_type === 5 ||
            project.pivot.flag_user_type === 6 ||
            project.pivot.flag_user_type === 7
          ) {
            /* Redirect user to dashboard */
            this.$router.push({ name: "administrator" });
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

<template>
  <div>
    <v-tooltip v-if="!readonly" left color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon
            :color="
              section === 1 && $vuetify.theme.dark === false
                ? 'white'
                : 'secondary'
            "
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Dissertation</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="800"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-card-title class="text-h5 secondary--text">
          Dissertation Details<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <span class="subtitle-1 font-weight-bold black--text"
                    >Add theses and/or dissertations for degrees earned during
                    the Track-2 award period only. Fields that are non-required
                    for DOP purposes are required for PI's annual RPPR thus
                    completion is recommended if you have data to enter.</span
                  >
                </v-col>
              </v-row>
              <v-row dense>
                <!--Title-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="title"
                    label="Title"
                    placeholder=" "
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Authors-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="authors"
                    label="Author(s) (e.g., Doe, Jane); if more than one, then Doe, Jane, Smith, Stan, and Jones, George in the order they appear on the thesis/dissertation) "
                    placeholder=" "
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Institution-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    v-model="institution"
                    :items="institutionsStore.institutionsValueList"
                    label="Institution"
                    placeholder=" "
                    item-text="name"
                    item-value="name"
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--NSF EPSCOR Support-->
                <v-col cols="6" sm="6">
                  <v-autocomplete
                    v-model="ack_nsf_epscor_support"
                    :items="valueListsStore.yesNo"
                    item-text="value"
                    item-value="value"
                    label="Acknowledgement of NSF EPSCoR Support"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                    @input="setFedAck(ack_nsf_epscor_support)"
                  />
                </v-col>
              </v-row>

              <v-row v-show="ack_nsf_epscor_support === 'No'" dense>
                <!--Description-->
                <v-col cols="12" sm="12">
                  <v-textarea
                    v-model="description"
                    label="Provide brief justification for including this thesis/dissertation"
                    placeholder=" "
                    grow
                    rows="2"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Federal Support Ack-->
                <v-col cols="6" sm="6">
                  <v-autocomplete
                    v-model="ack_federal_support"
                    :items="valueListsStore.yesNo"
                    item-text="value"
                    item-value="value"
                    label="Acknowledgement of Federal Support"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Type-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="type"
                    :items="valueListsStore.dissertationTypeList"
                    label="Type"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Month-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="month"
                    :items="valueListsStore.monthAbbreviations"
                    item-text="value"
                    item-value="value"
                    label="Month"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                  />
                </v-col>
                <!--Year-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="year"
                    :items="valueListsStore.projectYearList"
                    item-text="value"
                    item-value="value"
                    label="Year"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            v-if="!readonly"
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon small left> fal fa-check </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "dissertationsStore/getEntryField",
  mutationType: "dissertationsStore/updateEntryField",
});

export default {
  name: "Dissertation",
  mixins: [mixin],
  props: {
    section: Number,
    readonly: Boolean,
  },
  data() {
    return {
      rules: {
        not_empty: [(v) => !!v || ""],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      institutionsStore: (state) => state.institutionsStore,
      dissertationsStore: (state) => state.dissertationsStore,
      valueListsStore: (state) => state.valueListsStore,
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "view",

      "id",

      "id_user",
      "id_project",
      "title",
      "authors",
      "institution",
      "month",
      "year",
      "ack_federal_support",
      "description",
      "ack_nsf_epscor_support",
      "type",
    ]),
  },

  methods: {
    async newEntry() {
      this.$store.dispatch("dissertationsStore/resetEntry");
      await this.$_loadValueLists(23);

      const activeProject = this.$store.getters["usersStore/getActiveProject"];
      const activeUser = this.$store.getters["usersStore/getActiveUser"];

      this.view = this.section;
      this.id_user = activeUser.id;
      this.id_project = activeProject.id;

      this.show = true;

      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation();
      }
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        if (this.ack_nsf_epscor_support === "Yes") {
          this.description = "";
        }
        const entry = this.$store.getters["dissertationsStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("dissertationsStore/create", entry);
        } else {
          /* Edit */
          await this.$store.dispatch("dissertationsStore/update", entry);
        }
        this.cancelEntry();
      }
    },
    setFedAck(ack_nsf_epscor_support) {
      if (ack_nsf_epscor_support === "Yes") {
        this.ack_federal_support = "Yes";
        this.description = "";
      } else if (this.ack_nsf_epscor_support === "No") {
        this.ack_federal_support = "";
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

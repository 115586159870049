<template>
  <div>
    <v-tooltip
      v-if="!readonly"
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon
            :color="section === 1 && $vuetify.theme.dark === false ? 'white' : 'secondary'"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Fellowship</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="1200"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        flat
        max-height="900"
      >
        <v-card-title class="text-h5 secondary--text">
          Fellowship Details<v-spacer /><v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-container fluid>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <span class="caption error--text">* Required Fields</span>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Type-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="type"
                    :items="valueListsStore.fellowshipTypeList"
                    label="Type of Funding *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Other Type-->
                <v-col
                  v-if="type === 'Other'"
                  cols="12"
                  sm="12"
                  md="8"
                >
                  <v-text-field
                    v-model="type_other"
                    label="Specify Other Type *"
                    placeholder=" "
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Title-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="title"
                    label="Title *"
                    placeholder=" "
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Funding Organization-->
                <v-col
                  cols="4"
                  sm="4"
                >
                  <v-autocomplete
                    v-model="organization"
                    :items="valueListsStore.fellowshipOrganizationList"
                    label="Funding Organization *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Award Type-->
                <v-col
                  cols="8"
                  sm="8"
                >
                  <v-text-field
                    v-model="source"
                    label="Award Type (e.g., GRFP, NRSA, University dissertation award) *"
                    placeholder=" "
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Amount Requested-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="amount_requested"
                    label="Total Amount Requested - No Decimals  *"
                    placeholder=" "
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Submitted Date Month-->
                <v-col
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="date_month"
                    :items="valueListsStore.monthAbbreviations"
                    item-text="value"
                    item-value="value"
                    label="Submitted Date - Month *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Submitted Date Year-->
                <v-col
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="date_year"
                    :items="valueListsStore.projectYearList"
                    item-text="value"
                    item-value="value"
                    label="Submitted Date - Year *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.fellowshipStatusValueList"
                    label="Status *"
                    placeholder=" "
                    item-text="name"
                    item-value="status"
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="flag_status === 0 ? [] : rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              
                <!--Fellowship Date Start Month-->
                <v-col 
                  v-if="flag_status === 2"
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="date_awarded_month"
                    :items="valueListsStore.monthAbbreviations"
                    item-text="value"
                    item-value="value"
                    label="Fellowship Date Start - Month *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Fellowship Date Start Year-->
                <v-col
                  v-if="flag_status === 2"
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="date_awarded_year"
                    :items="valueListsStore.projectYearList"
                    item-text="value"
                    item-value="value"
                    label="Fellowship Date Start - Year *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Fellowship Date End Month-->
                <v-col
                  v-if="flag_status === 2"
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="date_awarded_month_end"
                    :items="valueListsStore.monthAbbreviations"
                    item-text="value"
                    item-value="value"
                    label="Fellowship Date End - Month *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Fellowship Date End Year-->
                <v-col
                  v-if="flag_status === 2"
                  cols="12"
                  sm="12"
                  md="2"
                >
                  <v-autocomplete
                    v-model="date_awarded_year_end"
                    :items="valueListsStore.projectYearList"
                    item-text="value"
                    item-value="value"
                    label="Fellowship Date End - Year *"
                    placeholder=" "
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Award Number-->
                <v-col
                  v-if="flag_status === 2"
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="award_number"
                    label="Award Number *"
                    placeholder=" "
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>

                <!--Amount Awarded-->
                <v-col 
                  v-if="flag_status === 2"
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="amount_awarded"
                    label="Total Amount Awarded - No Decimals *"
                    placeholder=" "
                    required
                    :rules="rules.not_empty"
                    :readonly="readonly"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            v-if="!readonly"
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mixin } from '@/mixins/mixin.js';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'fellowshipsStore/getEntryField',
  mutationType: 'fellowshipsStore/updateEntryField',
});

export default {
  name: 'Fellowship',
  mixins: [mixin],
  props: {
    /*1 = Dashboard, 2 = List*/
    section: Number,
    readonly: Boolean
  },
  data() {
    return {
      rules: {
       not_empty: [
          v => !!v || ''
        ]
      },
    }
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      fellowshipsStore: state => state.fellowshipsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',
      'view',

      'id',

      'id_user',
      'id_project',
      'type',
      'type_other',
      'title',
      'organization',
      'source',
      'date_month',
      'date_year',
      'award_number',
      'amount_requested',
      'amount_awarded',
      'date_awarded_month',
      'date_awarded_month_end',
      'date_awarded_year',
      'date_awarded_year_end',
      'flag_status',
    ]),
  },
  
  methods: {
    async newEntry() {

      const activeUser = this.$store.getters['usersStore/getActiveUser'];

      this.$store.dispatch('fellowshipsStore/resetEntry', activeUser.id);

      await this.$_loadValueLists(14);

      const activeProject = this.$store.getters['usersStore/getActiveProject'];

      this.view = this.section
      this.id_user = activeUser.id;
      this.id_project = activeProject.id
 
      this.show = true;

      if(this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation()
      }
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        if(this.type !== "Other"){
            this.type_other = ''
        }
        if(this.flag_status !== 2){
            this.date_awarded_month = ''
            this.date_awarded_year = ''
            this.date_awarded_month_end = ''
            this.date_awarded_year_end = ''
            this.award_number = ''
            this.amount_awarded = ''
        }
        const entry = this.$store.getters['fellowshipsStore/getEntry'];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch('fellowshipsStore/create', entry);
        }
        /* Edit */
        else {
          await this.$store.dispatch('fellowshipsStore/update', entry);
        }
        this.cancelEntry();
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>

<template>
  <div>
    <v-tooltip v-if="!readonly" left color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon
            :color="
              section === 1 && $vuetify.theme.dark === false
                ? 'white'
                : 'secondary'
            "
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Presentation</span>
    </v-tooltip>

    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="1200"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card height="950" flat>
        <v-card-title class="text-h5 secondary--text">
          Presentation Details
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left>
              fal fa-times </v-icon
            >Cancel
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-0 pt-4">
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tabs-slider light />

            <v-tab :key="1" :href="'#tab-1'">
              Presentation Information
            </v-tab>

            <v-tab :key="2" :href="'#tab-2'">
              T-2 Researchers
            </v-tab>

            <v-tab :key="3" :href="'#tab-3'">
              T-2 Trainees
            </v-tab>

            <v-tab :key="4" :href="'#tab-4'">
              Non T-2 Participants
            </v-tab>
          </v-tabs>
        </v-card-subtitle>
        <v-card-text class="py-0">
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-tabs-items v-model="tab">
              <!--General Information-->
              <v-tab-item :key="1" value="tab-1">
                <v-container class="py-0" fluid>
                  <v-card flat>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <span class="subtitle-1 font-weight-bold black--text"
                          >You will not be able to submit the record without
                          completing required fields. Fields that are
                          non-required for DOP purposes are required for PI's
                          annual RPPR thus completion is recommended.</span
                        >
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <span class="caption error--text"
                          >* Required Fields</span
                        >
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Author of Record-->
                      <v-col cols="12" sm="12" md="8">
                        <v-autocomplete
                          v-model="id_user"
                          :items="participantsStore.researchersValueList"
                          item-text="name_reverse"
                          item-value="id"
                          label="Author Of Record *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Status-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-model="flag_status"
                          :items="valueListsStore.presentationStatusValueList"
                          label="Status *"
                          placeholder=" "
                          item-text="name"
                          item-value="status"
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="flag_status === 0 ? [] : rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Presentation Type-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-model="type"
                          :items="valueListsStore.presentationTypeList"
                          label="Presentation Type *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Specify Other Type-->
                      <v-col v-if="type === 'Other'" cols="12" sm="12" md="8">
                        <v-text-field
                          v-model="type_other"
                          label="Specify Other Type *"
                          placeholder=" "
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Title-->
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="title"
                          label="Title *"
                          placeholder=" "
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--URL-->
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="url"
                          label="Website"
                          placeholder=" "
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Author-->
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="author"
                          label="Author(s) (e.g., Doe, Jane, Smith, Stan, and Jones, George) in the order they appear on the presentation"
                          placeholder=" "
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Support Acknowledgment-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-model="support_acknowledgment"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="EPSCoR T-2 Support Acknowledgment *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                          @input="setFedAck(support_acknowledgment)"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="support_acknowledgment === 'No'" dense>
                      <!--Support Acknowledgment Description-->
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="description"
                          label="Provide brief justification for including this presentation"
                          placeholder=" "
                          rows="2"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Federal Support Acknowledgment-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-model="acknowledgment_fed_support"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="Federal Support Acknowledgment"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Related T2-->
                      <v-col cols="12" sm="12" md="4">
                        <v-autocomplete
                          v-model="related_t2"
                          :items="valueListsStore.yesNo"
                          item-text="value"
                          item-value="value"
                          label="Was presentation based on work supported by this Track-2 award? *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                          @input="setFedAck(related_t2)"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="related_t2 === 'No'" dense>
                      <!--Related T2 Description-->
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="related_t2_description"
                          label="Provide brief justification for including this presentation *"
                          placeholder=" "
                          rows="2"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Conference Name-->
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="event"
                          label="Conference Name *"
                          placeholder=" "
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Location of Presentation-->
                      <v-col cols="12" sm="12">
                        <v-autocomplete
                          v-model="location"
                          :items="valueListsStore.presentationLocationList"
                          label="Location of Presentation *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--City State-->
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="cityState"
                          label="City, State, Country"
                          placeholder=" "
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="flag_status === 1" dense>
                      <!--Date Month-->
                      <v-col cols="6" sm="6" md="2">
                        <v-autocomplete
                          v-model="date_month"
                          :items="valueListsStore.monthAbbreviations"
                          item-text="value"
                          item-value="value"
                          label="Presentation Date - Month *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Date Year-->
                      <v-col cols="6" sm="6" md="2">
                        <v-autocomplete
                          v-model="date_year"
                          :items="valueListsStore.projectYearList"
                          item-text="value"
                          item-value="value"
                          label="Presentation Date - Year *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--Estimated Published Date Month-->
                      <v-col
                        v-if="flag_status && flag_status !== 1"
                        cols="6"
                        sm="6"
                        md="2"
                      >
                        <v-autocomplete
                          v-model="date_estimated_published_month"
                          :items="valueListsStore.monthAbbreviations"
                          item-text="value"
                          item-value="value"
                          label="Estimated Presentation Date - Month *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>

                      <!--Estimated Published Date Year-->
                      <v-col
                        v-if="flag_status && flag_status !== 1"
                        cols="6"
                        sm="6"
                        md="2"
                      >
                        <v-autocomplete
                          v-model="date_estimated_published_year"
                          :items="valueListsStore.projectYearList"
                          item-text="value"
                          item-value="value"
                          label="Estimated Presentation Date - Year *"
                          placeholder=" "
                          :clearable="readonly !== true"
                          clear-icon="fal fa-times-circle"
                          required
                          :rules="rules.not_empty"
                          :readonly="readonly"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-container>
              </v-tab-item>

              <!--T-2 Researchers-->
              <v-tab-item :key="2" value="tab-2">
                <participants-selector
                  :user-type="1"
                  :section="4"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>

              <!--T-2 Trainees-->
              <v-tab-item :key="3" value="tab-3">
                <participants-selector
                  :user-type="2"
                  :section="4"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>

              <!--Non T-2 Participants-->
              <v-tab-item :key="4" value="tab-4">
                <participants-selector
                  :user-type="3"
                  :section="4"
                  :role-selector="0"
                  :readonly="readonly"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-card-text>

        <v-card-actions class="mx-4 pb-4">
          <v-row dense>
            <v-col
              v-if="tab === 'tab-2' || tab === 'tab-3' || tab === 'tab-4'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                color="red"
                dark
                outlined
                @click="previousTab()"
              >
                <v-icon small left>
                  fal fa-arrow-left </v-icon
                >Back
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col v-if="tab === 'tab-4' && !readonly" cols="3" sm="3">
              <v-btn block rounded color="primary" @click="saveEntry()">
                <v-icon small left>
                  fal fa-check </v-icon
                >Submit
              </v-btn>
            </v-col>

            <v-col
              v-if="tab === 'tab-1' || tab === 'tab-2' || tab === 'tab-3'"
              cols="3"
              sm="3"
            >
              <v-btn
                block
                rounded
                class="green darken-2"
                dark
                @click="nextTab()"
              >
                Next
                <v-icon small right>
                  fal fa-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import { createHelpers } from "vuex-map-fields";
import ParticipantsSelector from "@/components/ParticipantsSelector";

const { mapFields: mapEntryFields } = createHelpers({
  getterType: "presentationsStore/getEntryField",
  mutationType: "presentationsStore/updateEntryField",
});

export default {
  name: "Presentation",
  components: {
    ParticipantsSelector,
  },
  mixins: [mixin],
  props: {
    section: Number,
    readonly: Boolean,
  },
  data() {
    return {
      rules: {
        not_empty: [(v) => !!v || "Required"],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      projectsStore: (state) => state.projectsStore,
      participantsStore: (state) => state.participantsStore,
      presentationsStore: (state) => state.presentationsStore,
      valueListsStore: (state) => state.valueListsStore,
    }),
    ...mapEntryFields([
      "show",
      "valid",
      "mode",
      "view",
      "id",

      "tab",

      /*-----ADD ENTRY FIELDS BELOW-----*/
      "id_user",
      "id_project",
      "title",
      "author",
      "url",
      "type",
      "type_other",
      "support_acknowledgment",
      "description",
      "related_t2",
      "related_t2_description",
      "acknowledgment_fed_support",
      "event",
      "location",
      "cityState",
      "date",
      "date_month",
      "date_year",
      "date_estimated_published_month",
      "date_estimated_published_year",
      "flag_status",
      "flag_show",

      "participants",
      "participants_selected",
      "non_t2_participants",
      "non_t2",
    ]),
  },

  methods: {
    async newEntry() {
      this.$store.dispatch("presentationsStore/resetEntry");

      this.$store.dispatch("participantsStore/resetEntry");

      await this.$_loadValueLists(13);

      const authUser = this.$store.getters["usersStore/getAuthUser"];

      const activeProject = this.$store.getters["usersStore/getActiveProject"];
      const activeUser = this.$store.getters["usersStore/getActiveUser"];
      const activeUserType = this.$store.getters[
        "usersStore/getActiveUserType"
      ];

      if (activeUserType === 3) {
        let newUser = {
          id: activeUser.id,
          name_reverse: activeUser.name_reverse,
        };

        await this.$store.dispatch("participantsStore/addAuthUser", newUser);
      }

      const data = {
        id: activeUser.id,
        role: "",
        name_reverse: activeUser.name_reverse,
        type: activeUserType === 1 || activeUserType === 2 ? 1 : 2,
      };

      await this.$store.dispatch("participantsStore/addParticipant", data);

      this.view = this.section;
      this.id_user = activeUser.id;
      this.id_project = activeProject.id;

      this.show = true;

      if (this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation();
      }
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      const supervisorsSelected =
        this.$store.getters["participantsStore/getParticipantsSelected"].length > 0;

      if (this.$refs.entryForm.validate() && supervisorsSelected) {
        if (this.type !== "Other") {
          this.type_other = "";
        }
        if (this.support_acknowledgment === "Yes") {
          this.description = "";
        }
        if (this.flag_status !== 1) {
          this.date_month = "";
          this.date_year = "";
        }
        if (this.flag_status === 1) {
          this.date_estimated_published_month = "";
          this.date_estimated_published_year = "";
        }
        let participants_selected = this.$store.getters[
          "participantsStore/getParticipantsSelected"
        ];
        let non_t2_participants = this.$store.getters[
          "participantsStore/getNonT2"
        ];

        this.participants_selected = participants_selected;
        this.non_t2 = non_t2_participants;

        let entry = this.$store.getters["presentationsStore/getEntry"];

        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("presentationsStore/create", entry);
        } else {
        /* Edit */
          await this.$store.dispatch("presentationsStore/update", entry);
        }
        this.cancelEntry();

        /*If saving from the dashboard*/
        if (this.section === 1) {
          const activeUser = this.$store.getters["usersStore/getActiveUser"];
          /* Update users list that shows totals */
          let filters = {
            id_project: this.id_project,
            activeUser: activeUser.id,
            trainee_flag_user_type: 3,
            sectionStored: 9,
            page: 1,
          };
          await this.$store.dispatch("usersStore/fetch", filters);
        }
      } else {
        this.tab = "tab-1";
      }
    },

    nextTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);

      //if (tabNum === 1 && !this.$refs.entryForm.validate()) return;

      tabNum++;

      this.tab = `tab-${tabNum}`;
    },

    previousTab() {
      var tabNum = Number.parseInt(this.tab.substr(4, 1), 10);
      tabNum--;

      this.tab = `tab-${tabNum}`;
    },
    setFedAck(support_acknowledgment) {
      if (support_acknowledgment === "Yes") {
        this.acknowledgment_fed_support = "Yes";
        this.description = "";
      } else if (support_acknowledgment === "No") {
        this.acknowledgment_fed_support = "";
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

<!--Prop Section => 1 = Dashboard, 2 = List-->
<template>
  <div>
    <!-- Title Bar - Only Shown On Dashboard-->
    <v-card
      v-if="section === 1 && progressStore.progress !== true"
      flat
    >
      <v-toolbar
        dense
        flat
        color="primary"
        class="mb-2"
      >
        <v-icon :color="$vuetify.theme.dark === false ? 'white' : 'secondary'">
          fal fa-graduation-cap
        </v-icon>
        <v-toolbar-title class="pl-2">
          <span :class="$vuetify.theme.dark === false ? 'white--text' : 'secondary--text'">4 Most Recent Dissertations</span>
        </v-toolbar-title>
        <v-spacer />

        <dissertation
          :section="section" 
          :readonly="usersStore.authUserType === 4 || usersStore.authUserType === 7"
        />
      </v-toolbar>
    </v-card>

    <!--Title Bar - Only Shown On List View-->
    <title-bar
      v-if="section === 2"
      :title-value="'Dissertations'"
    />

    <!-- Filter - Only Shown On List View-->
    <v-toolbar
      v-if="section === 2"
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!-- Title -->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="title"
                    label="Title"
                    placeholder=" "
                  />
                </v-col>
                
                <!--Type-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="type"
                    :items="valueListsStore.dissertationTypeList"
                    label="Type"
                    placeholder=" "
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="dissertationsStore.dissertations.data && dissertationsStore.dissertations.data.length > 0 && dissertationsStore.dissertations.last_page > 1 ? true : false"
        :current-page="dissertationsStore.dissertations.current_page"
        :last-page="dissertationsStore.dissertations.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <!--Export Excel-->
      <export-excel :section="23" />

      <!--Entry Dialog-->
      <dissertation
        :section="section"
        :readonly="usersStore.authUserType === 4 || usersStore.authUserType === 7"
      />

      <!-- Delete Dialog -->
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500"
      >
        <v-card
          flat
        >
          <v-card-title class="text-h5 secondary--text">
            Delete Dissertation Record?
            <v-spacer />
            <v-btn
              text
              rounded
              outlined
              @click="cancelDelete()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Cancel
            </v-btn>
          </v-card-title>
          <v-card-text
            class="text-center px-10 py-4 secondary--text"
          >
            <v-row
              justify="center"
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <span class="text-subtitle-1  secondary--text font-italic">
                  Are you sure you want to delete this Dissertation record?
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-4 pb-4">
            <v-row
              dense
            >
              <v-spacer />

              <v-col
                cols="12"
                sm="12"
              >
                <v-btn
                  block
                  rounded
                  color="red"
                  dark
                  @click="deleteRecord()"
                >
                  <v-icon
                    small
                    left
                  >
                    fal fa-trash-alt
                  </v-icon>Yes, Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    
    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="dissertationsStore.dissertations.data && dissertationsStore.dissertations.data.length > 0 && section === 1"
        height="200px"
        :fixed-header="true"
        class="my-2"
        dense
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">
                ID
              </th>
              <th class="text-left grey--text text--darken-1">
                Type
              </th>
              <th class="text-left grey--text text--darken-1">
                Last Edit
              </th>
              <th class="text-right grey--text text--darken-1" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in dissertationsStore.dissertations.data"
              :key="item.id"
            >
              <td class="text-left secondary--text">
                {{ item.id }}
              </td>
              <td class="text-left secondary--text">
                {{ item.type }}
              </td>
              <td class="text-left secondary--text">
                {{ $_timestampToDate(item.updated_at) }}
              </td>
              <td class="text-right secondary--text">
                <v-tooltip
                  left
                  color="primary"
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="openEntry(item.id)"
                    >
                      <v-icon
                        v-if="usersStore.authUserType === 4 || usersStore.authUserType === 7"
                        small
                        color="primary"
                      >
                        fal fa-eye
                      </v-icon>

                      <v-icon
                        v-else
                        small
                        color="primary"
                      >
                        fal fa-pencil-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-if="usersStore.authUserType === 4 || usersStore.authUserType === 7">View</span>
                  <span v-else>Edit</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list
        v-else-if="section === 1"
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
              No Dissertations Exist
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!--Data Point Bar-->
      <v-toolbar
        v-if="section === 1 && progressStore.progress !== true"
        flat
        dense
      >
        <span class="text-h6 green--text text--lighten-1">{{ dissertationsStore.dissertations.total }}</span>
        <v-chip class="mx-2 green lighten-1 white--text">
          TOTAL
        </v-chip>
      </v-toolbar>

      <!--List-->
      <list-basic
        v-if="section === 2"
        :show="dissertationsStore.dissertations.data && dissertationsStore.dissertations.data.length > 0 ? true : false"
        :list-data="dissertationsStore.dissertations.data"
        :title="'Dissertations'"
        :section="section === 1 ? 23.1 : 23.2"
        :button-title="'Dissertation'"
        :actions="actions"
        @open-entry="openEntry($event)"
        @delete-entry="deleteEntry($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import Dissertation from '@/components/Dissertation';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';
import ExportExcel from '@/components/ExportExcel'

const { mapFields } = createHelpers({
  getterType: 'dissertationsStore/getFilterField',
  mutationType: 'dissertationsStore/updateFilterField',
});

export default {
  name: 'Dissertations',
  components: {
    TitleBar,
    Dissertation,
    Pagination,
    ListBasic,
    ExportExcel
  },
  mixins: [mixin],
  props: {
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      deleteDialog: false,
      actions: {
        menu: 0,
        view: 0,
        edit: 1,
        password:0,
        invite:0,
        email:0,
        delete:this.section === 2 ? 1 : 0
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      progressStore: state => state.progressStore,
      institutionsStore: state => state.institutionsStore,
      dissertationsStore: state => state.dissertationsStore,
      valueListsStore: (state) => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'view',
      'export',
      'id_user',
      'id_project',

      'title',
      'type'
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {

      this.$store.dispatch('progressStore/set',true)

      let user = this.$store.getters['usersStore/getActiveUser'];
      let project = this.$store.getters['usersStore/getActiveProject'];
      this.id_user = user.id
      this.id_project = project.id
      this.view = this.section;
      this.export = 0

	    /* Grab filters */
      let filters = this.$store.getters['dissertationsStore/getFilters'];
      
      /* Grab dissertations based on active filters */
      await this.$store.dispatch('dissertationsStore/fetch', filters);
      this.$store.dispatch('progressStore/set',false);
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
	  },

	  async clearFilter() {
      this.filterMenu = false;
      this.title = '';
      this.type = '';
      this.page = 1;
		  this.initialize();
	  },

	  async openEntry(id) {
      await this.$_loadValueLists(23);

      this.$store.dispatch('dissertationsStore/setView', this.section)

      await this.$store.dispatch('dissertationsStore/entry', id);
      if(this.$refs.entryForm) {
        this.$refs.entryForm.resetValidation()
      }
    },

    async deleteEntry(id) {
      await this.$store.dispatch('dissertationsStore/read', id);
      this.deleteDialog = true;
    },

    async deleteRecord() {
      const record = this.$store.getters['dissertationsStore/getDissertation'];

      const data = {
        id: record.id
      };

      await this.$store.dispatch('dissertationsStore/delete', data);
      this.deleteDialog = false;
    },

    cancelDelete() {
      this.deleteDialog = false;
    },
    
    showFiltered(){
      if(this.title || this.type) {
        return true;
      }
    }

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>

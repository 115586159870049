<template>
  <div>
    <v-tooltip v-if="addNew === 1" left color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span v-if="section === 3 || section === 5">Add Researcher</span>
      <span v-else-if="section === 4 || section === 6">Add Trainee</span>
      <span v-else-if="section === 7">Add Other Project User</span>
      <span v-else-if="section === 8">Add RPPR User</span>
      <span v-else>Add Participant</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="1000"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-card-title class="text-h5 secondary--text">
          Participation Details
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="saveEntry()"
          >
            <v-container fluid>
              <v-row v-if="!id_user && emailCheck === 1" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 error--text"
                    >Adding a new user to the project</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="!id && id_user && emailCheck === 1" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text"
                    >Adding an existing user to the project</span
                  >
                </v-col>
              </v-row>

              <v-row v-else-if="id && id_user && emailCheck === 1" dense>
                <v-col cols="12" sm="12" class="text-center">
                  <span class="text-h6 success--text"
                    >User is active on the project</span
                  >
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Email-->
                    <v-col cols="12" sm="12">
                      <text-display
                        v-if="id_user"
                        :display-string="email"
                        label="Email"
                      />
                      <v-text-field
                        v-else
                        v-model="email"
                        label="Email"
                        placeholder=" "
                        required
                        :rules="rules.email"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--First Name-->
                    <v-col cols="12" sm="12">
                      <text-display
                        v-if="id_user"
                        :display-string="name_first"
                        :label="'First Name'"
                      />
                      <v-text-field
                        v-else-if="emailCheck === 1"
                        v-model="name_first"
                        label="First Name"
                        placeholder=" "
                        required
                        :rules="rules.name_first"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Last Name-->
                    <v-col cols="12" sm="12">
                      <text-display
                        v-if="id_user"
                        :display-string="name_last"
                        :label="'Last Name'"
                      />
                      <v-text-field
                        v-else-if="emailCheck === 1"
                        v-model="name_last"
                        label="Last Name"
                        placeholder=" "
                        required
                        :rules="rules.name_last"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="!id_user" dense>
                    <!--Password-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-if="!id_user && emailCheck === 1"
                        v-model="password"
                        label="Password"
                        placeholder=" "
                        :required="mode === 1 ? true : false"
                        :rules="mode === 1 ? rules.password : []"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="!id_user" dense>
                    <!--Password Confirmation-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-if="!id_user && emailCheck === 1"
                        v-model="passwordConfirmation"
                        label="Confirm Password"
                        placeholder=" "
                        :required="mode === 1 ? true : false"
                        :rules="mode === 1 ? rules.passwordConfirmation : []"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row v-if="emailCheck === 1" dense>
                    <!--User Types-->
                    <v-col cols="12" sm="9">
                      <v-select
                        v-model="flag_user_type"
                        :items="valueListsStore.userTypes"
                        label="User Type"
                        placeholder=" "
                        item-value="id"
                        item-text="value"
                        :clearable="
                          usersStore.authUser.flag_admin === 1 ? true : false
                        "
                        clear-icon="fal fa-times-circle"
                        :readonly="
                          usersStore.authUser.flag_admin === 1 ? false : true
                        "
                        :required="
                          usersStore.authUser.flag_admin === 1 ? false : true
                        "
                        :rules="
                          usersStore.authUser.flag_admin === 1
                            ? []
                            : rules.not_empty
                        "
                      />
                    </v-col>

                    <!--Contact PI-->
                    <v-col
                      v-if="
                        emailCheck === 1 &&
                          usersStore.authUser.flag_admin === 1 &&
                          flag_user_type === 1
                      "
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="flag_contact_pi"
                        :items="valueListsStore.yesNo"
                        label="Contact PI"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      flag_user_type === 1 ||
                        flag_user_type === 2 ||
                        flag_user_type === 3
                    "
                    dense
                  >
                    <!--Classification-->
                    <v-col
                      v-if="
                        usersStore.authUserType === 1 ||
                          (usersStore.authUserType === 2 &&
                            flag_user_type === 3) ||
                          usersStore.authUserType === 5 ||
                          usersStore.authUserType === 6 ||
                          usersStore.authUser.flag_admin === 1
                      "
                      cols="12"
                      sm="12"
                      md="9"
                    >
                      <v-autocomplete
                        v-model="classification"
                        :items="
                          flag_user_type === 1 || flag_user_type === 2
                            ? valueListsStore.classificationsResearchers
                            : valueListsStore.classificationsTrainees
                        "
                        label="Classification *"
                        placeholder=" "
                        item-text="value"
                        item-value="value"
                        :clearable="
                          usersStore.authUser.flag_admin === 1 ? true : false
                        "
                        clear-icon="fal fa-times-circle"
                        :required="
                          usersStore.authUser.flag_admin === 1 ? false : true
                        "
                        :rules="
                          usersStore.authUser.flag_admin === 1
                            ? []
                            : rules.not_empty
                        "
                        :readonly="
                          usersStore.authUser.flag_admin === 1 ? false : true
                        "
                      />
                    </v-col>

                    <!--Other Classification-->
                    <v-col
                      v-if="
                        (usersStore.authUserType === 1 &&
                          classification === 'Other') ||
                          (usersStore.authUserType === 2 &&
                            usersStore.profile.flag_user_type === 3 &&
                            classification === 'Other') ||
                          (usersStore.authUserType === 5 &&
                            classification === 'Other') ||
                          (usersStore.authUserType === 6 &&
                            classification === 'Other') ||
                          (usersStore.authUser.flag_admin === 1 &&
                            classification === 'Other')
                      "
                      cols="12"
                      sm="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="classification_other"
                        label="Specify Other Classification"
                        placeholder=" "
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="emailCheck === 1" dense>
                    <!--Start Month-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_start_month"
                        :items="valueListsStore.monthAbbreviations"
                        item-text="value"
                        item-value="value"
                        label="Start Month"
                        placeholder=" "
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        clear-icon="fal fa-times-circle"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>

                    <!--Start Year-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_start_year"
                        :items="valueListsStore.projectYearList"
                        item-text="value"
                        item-value="value"
                        label="Start Year"
                        placeholder=" "
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        clear-icon="fal fa-times-circle"
                        required
                        :rules="rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="emailCheck === 1 && mode === 2" dense>
                    <!--Status-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        v-model="flag_status"
                        :items="valueListsStore.activeStatus"
                        label="Status"
                        placeholder=" "
                        item-value="id"
                        item-text="value"
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        clear-icon="fal fa-times-circle"
                        required
                        :rules="flag_status === 0 ? [] : rules.not_empty"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="emailCheck === 1 && mode === 2 && flag_status !== 1"
                    dense
                  >
                    <!--End Month-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_end_month"
                        :items="valueListsStore.monthAbbreviations"
                        item-text="value"
                        item-value="value"
                        label="End Month *"
                        placeholder=" "
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        required
                        :rules="rules.not_empty"
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--End Year-->
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="date_end_year"
                        :items="valueListsStore.projectYearList"
                        item-text="value"
                        item-value="value"
                        label="End Year *"
                        placeholder=" "
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        required
                        :rules="rules.not_empty"
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      usersStore.authUser.flag_admin === 1 && emailCheck === 1
                    "
                    dense
                  >
                    <!--Include in emails-->
                    <v-col cols="12" sm="12" md="6">
                      <v-autocomplete
                        v-model="flag_email"
                        :items="valueListsStore.yesNo"
                        label="Include In Mass Emails"
                        placeholder=" "
                        item-text="value"
                        item-value="id"
                        :clearable="
                          section !== 5 && section !== 6 && section !== 8
                        "
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <!--Researchers List For Trainees-->
                  <v-row v-if="showParticipantsSelector" dense>
                    <v-col cols="12" sm="12">
                      <participants-selector
                        :user-type="1"
                        :section="6"
                        :role-selector="0"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn block rounded color="primary" @click="saveEntry()">
            <v-icon small left> fal fa-check </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";
import ParticipantsSelector from "@/components/ParticipantsSelector";

const { mapFields } = createHelpers({
  getterType: "participationStore/getEntryField",
  mutationType: "participationStore/updateEntryField",
});

export default {
  name: "ParticipationEntry",
  components: {
    TextDisplay,
    ParticipantsSelector,
  },
  mixins: [mixin],
  props: {
    /*1 = User Details, 2 = Project Details, 3 = Researcher List, 4 = Trainee List*/
    section: Number,
    addNew: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        name_first: [(v) => !!v || "Required"],
        name_last: [(v) => !!v || "Required"],
        email: [(v) => !!v || "Required"],
        password: [(v) => !!v || "Required"],
        flag_status: [(v) => !!v || "Required"],
        flag_admin: [(v) => !!v || "Required"],
        flag_hide: [(v) => !!v || "Required"],
        flag_show: [(v) => !!v || "Required"],
        not_empty: [(v) => !!v || "Required"],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      valueListsStore: (state) => state.valueListsStore,
      projectsStore: (state) => state.projectsStore,
      participationStore: (state) => state.participationStore,
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",

      "id_project",
      "date_start_project",
      "id_user",
      "email",
      "name_first",
      "name_last",
      "password",
      "passwordConfirmation",
      "date_start_month",
      "date_start_year",
      "date_end_month",
      "date_end_year",
      "flag_contact_pi",
      "flag_user_type",
      "flag_status",
      "emailCheck",
      "advisors",
      "classification",
      "classification_other",
      "sendInvite",
      "flag_email",
    ]),
    showParticipantsSelector() {
      if (
        (this.emailCheck == 1 && this.flag_user_type == 3 && this.id_project) ||
        (this.emailCheck == 1 && this.flag_user_type == 8 && this.id_project)
      )
        return true;
      else return false;
    },
  },
  methods: {
    async newEntry() {
      this.$store.dispatch("participationStore/resetEntry");
      this.$store.dispatch("participantsStore/clearParticipantsSelected");
      let project;
      if (
        this.section === 12 ||
        this.section === 4 ||
        this.section === 5 ||
        this.section === 6 ||
        this.section === 7 ||
        this.section === 8
      ) {
        if (this.section === 12) {
          project = this.$store.getters["projectsStore/getProject"];
        } else {
          project = this.$store.getters["usersStore/getActiveProject"];
        }
        this.id_project = project.id;
        this.date_start_project = project.date_start;
        let yearStart = this.$_formatDateYear(project.date_start);
        let data = {
          yearStart: yearStart,
        };
        await this.$store.dispatch("valueListsStore/setProjectYearList", data);
      }
      if (this.section === 5) {
        this.flag_user_type = 2;
      }
      if (this.section === 6) {
        this.flag_user_type = 3;
      }
      if (this.section === 8) {
        this.flag_user_type = 8;
      }
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      let project2;
      if (this.section === 11 || this.section === 12) {
        project2 = this.$store.getters["projectsStore/getProject"];
      } else {
        project2 = this.$store.getters["usersStore/getActiveProject"];
      }
      if (
        this.$_projectDateStart(
          project2.date_start,
          this.date_start_month,
          this.date_start_year
        )
      ) {
        const supervisorsSelected =
          this.$store.getters["participantsStore/getParticipantsSelected"]
            .length > 0;

        if (
          (this.$refs.entryForm.validate() && !this.showParticipantsSelector) ||
          (this.$refs.entryForm.validate() &&
            this.showParticipantsSelector &&
            supervisorsSelected)
        ) {
          if (this.flag_status === 1) {
            this.date_end_month = "";
            this.date_end_year = "";
          }

          this.advisors = this.$store.getters[
            "participantsStore/getParticipantsSelected"
          ];

          const entry = this.$store.getters["participationStore/getEntry"];

          if (!this.emailCheck) {
            await this.$store.dispatch("participationStore/readByEmail", entry);
            if (this.id_user) {
              let project = this.$store.getters["projectsStore/getProject"];
              let data = {
                id_user: this.id_user,
                id_project: project.id,
              };
              await this.$store.dispatch(
                "participationStore/activeProjectUser",
                data
              );
            }
          } else {
            /* Create */
            if (!this.id) {
              await this.$store.dispatch("participationStore/create", entry);
            } else {
              /* Edit */
              await this.$store.dispatch("participationStore/update", entry);
            }
            this.cancelEntry();
          }
        }
      } else {
        /* Show error notification */
        let data = {
          color: "error",
          text:
            "Start date must be later than project start of " +
            this.$_formatDate(project2.date_start),
        };
        this.$store.commit("messagesStore/setMessage", data);
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>

<template>
  <div>
    <!-- Title Bar - Only Shown On Dashboard-->
    <v-card
      v-if="section === 9 && progressStore.progress !== true"
      flat
    >
      <v-toolbar
        dense
        flat
        color="primary"
        class="mb-2"
      >
        <v-icon :color="$vuetify.theme.dark === false ? 'white' : 'secondary'">
          fal fa-id-badge
        </v-icon>
        <v-toolbar-title class="pl-2">
          <span :class="$vuetify.theme.dark === false ? 'white--text' : 'secondary--text'">Trainees</span>
        </v-toolbar-title>
        <v-spacer />

        <user
          v-if="section === 9"
          :section="section"
        />
      </v-toolbar>
    </v-card>

    <!--Title Bar-->
    <title-bar
      v-if="section === 4 && usersStore.adminMode !== 1"
      :title-value="'Trainees'"
    />

    <!-- Filter -->
    <v-toolbar
      v-if="section !== 9"
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <!----------Trainees Users List---------->
              <v-row dense>
                <!--User ID-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="trainee_id"
                    label="User ID"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--First Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="trainee_name_first"
                    label="First Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Last Name-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="trainee_name_last"
                    label="Last Name"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Email-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    placeholder=" "
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="usersStore.trainees.data && usersStore.trainees.data.length > 0 && usersStore.trainees.last_page > 1 ? true : false"
        :current-page="usersStore.trainees.current_page"
        :last-page="usersStore.trainees.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <!--Export Excel-->
      <export-excel
        v-if="section !== 9"
        :section="5"
        :sub-section="section"
      /> 

      <!--Entry Dialog-->
      <participation-entry 
        v-if="1 == 2"
        :add-new="1"
        :section="section" 
      />

      <user :section="section" />

      <!-- Delete Dialog -->
      <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="500"
      >
        <v-card
          flat
        >
          <v-card-title class="text-h5 secondary--text">
            Delete User Record?
            <v-spacer />
            <v-btn
              text
              rounded
              outlined
              @click="cancelDelete()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Cancel
            </v-btn>
          </v-card-title>
          <v-card-text
            class="text-center px-10 py-4 secondary--text"
          >
            <v-row
              justify="center"
              dense
            >
              <v-col
                cols="12"
                sm="12"
              >
                <span class="text-subtitle-1  secondary--text font-italic">
                  Are you sure you want to delete this User record?
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-4 pb-4">
            <v-row
              dense
            >
              <v-spacer />

              <v-col
                cols="12"
                sm="12"
              >
                <v-btn
                  block
                  rounded
                  color="red"
                  dark
                  @click="deleteRecord()"
                >
                  <v-icon
                    small
                    left
                  >
                    fal fa-trash-alt
                  </v-icon>Yes, Delete
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    
    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="usersStore.trainees.data && usersStore.trainees.data.length > 0"
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">
                ID
              </th>

              <th class="text-left grey--text text--darken-1">
                Name
              </th>

              <th class="text-left grey--text text--darken-1">
                Email
              </th>

              <th class="text-left grey--text text--darken-1">
                Classification
              </th>

              <th class="text-left grey--text text--darken-1">
                Institution
              </th>

              <th
                class="text-left grey--text text--darken-1"
              >
                Fellowships Submitted
              </th>

              <th
                class="text-left grey--text text--darken-1"
              >
                Fellowships Funded
              </th>

              <th
                class="text-left grey--text text--darken-1"
              >
                Publications
              </th>

              <th
                class="text-left grey--text text--darken-1"
              >
                Presentations
              </th>

              <th class="text-left grey--text text--darken-1">
                Status
              </th>

              <th class="text-left grey--text text--darken-1">
                Access
              </th>

              <th class="text-right grey--text text--darken-1">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="item in (usersStore.trainees.data )"
              :key="item.id"
            >
              <!--ID-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.id }}
              </td>
              <!--Name-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.name_reverse }}
              </td>
              <!--Email-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.email }}
              </td>
              <!--Classification-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.classification }}
              </td>
              <!--Institution-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.institution }}
              </td>
              <!--Proposals Submitted-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.fellowshipsSubmitted }}
              </td>
              <!--Proposals Funded-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.fellowshipsFunded }}
              </td>
              <!--Publications-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.publicationsTotal }}
              </td>
              <!--Presentations Total-->
              <td
                class="text-left"
                :class="$vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.presentationsTotal }}
              </td>
              <!--User Status-->
              <td
                class="text-left"
                :class="item.userStatus === 'Inactive' ? 'error--text' : $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ item.userStatus }}
              </td>

              <td
                class="text-left"
                :class="item.flag_status === 0 ? 'error--text' : $vuetify.theme.dark === false ? 'light-blue--text text--darken-4' : 'secondary--text'"
              >
                {{ $_access(item.flag_status) }}
              </td>
              <td class="text-right light-blue--text text--darken-4">
                <!--Action Menu-->
                <v-menu
                  v-if="actions.menu === 1"
                  open-on-click
                  offset-y
                  left
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      large
                      v-on="on"
                    >
                      <v-avatar
                        size="32px"
                        tile
                      >
                        <v-icon
                          color="primary"
                        >
                          fal fa-cog
                        </v-icon>
                      </v-avatar>
                    </v-btn>
                  </template>

                  <v-list>
                    <!--View User Portal-->
                    <v-list-item
                      @click.stop="$_switchUser(item.id, section)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-tachometer-alt
                        </v-icon> View Portal
                      </v-list-item-title>
                      <v-divider />
                    </v-list-item>

                    <!--Action 1: View Detail-->
                    <v-list-item
                      @click.stop="openDetail(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-eye
                        </v-icon> View User
                      </v-list-item-title>
                      <v-divider />
                    </v-list-item>

                    <!--Action 2: Edit Record-->
                    <v-list-item
                      v-if="1 === 2"
                      @click.stop="openEntry(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-pencil-alt
                        </v-icon> Edit User
                      </v-list-item-title>
                    </v-list-item>

                    <!--Action 3: Change Password-->
                    <v-list-item
                      v-if="usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                      @click.stop="$_openChangePassword(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-key
                        </v-icon> Change Password
                      </v-list-item-title>
                    </v-list-item>

                    <!--Action 4: Invite-->
                    <v-list-item
                      v-if="usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                      @click.stop="$_invite(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-envelope-open-text
                        </v-icon> Invite
                      </v-list-item-title>
                    </v-list-item>

                    <!--Action 5: Email-->
                    <v-list-item
                      v-if="usersStore.authUserType !== 4 && usersStore.authUserType !== 7"
                      @click.stop="$_openEmail(item.id)"
                    >
                      <v-list-item-title class="clickable primary--text">
                        <v-icon
                          color="primary"
                          left
                        >
                          fal fa-at
                        </v-icon> Email
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-list
        v-else
        class="py-2"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center">
              No Trainees Exist
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { mixin } from '@/mixins/mixin.js';
import TitleBar from '@/components/TitleBar';
import ParticipationEntry from '@/components/ParticipationEntry';
import User from '@/components/User';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';
import ExportExcel from '@/components/ExportExcel'

const { mapFields } = createHelpers({
  getterType: 'usersStore/getTraineeFilterField',
  mutationType: 'usersStore/updateTraineeFilterField',
});

export default {
  name: 'Users',
  components: {
    TitleBar,
    ParticipationEntry,
    User,
    Pagination,
    ListBasic,
    ExportExcel,
  },
  mixins: [mixin],
  props: {
     /*
     1 = Settings Users List,
     2 = RPPR List,
     3 = Project Detail Users List,
     4 = Trainees List (Researcher List Of Trainees),
     5 = Admin Researchers List,
     6 = Admin Trainees List,
     7 = Admin Others Project Users List,
     8 = Admin RPPR List,
     9 = Trainees Dashboard,
     10 = RPPR Researchers List,
     */
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      deleteDialog: false,
      actions: {
        menu:1,
        view:1,
        edit:0,
        password:1,
        invite:1,
        email:1,
        delete: 1
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: state => state.progressStore,
      usersStore: state => state.usersStore,
      participantsStore: state => state.participantsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'sectionStored',
      'export',

      'activeUser',

      'id_project',

      'trainee_id',
      'trainee_name_first',
      'trainee_name_last',
      'trainee_flag_user_type',
      'email'
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.$store.dispatch('progressStore/set',true);

      const activeUser = this.$store.getters['usersStore/getActiveUser'];

      this.export = 0

      this.changeSectionCheck();

      let project = this.$store.getters['usersStore/getActiveProject'];
      this.id_project = project.id;
      this.trainee_flag_user_type = 3;

      this.activeUser = activeUser.id

      this.sectionStored = this.section;

      /* Grab filters */
      const filters = this.$store.getters['usersStore/getTraineeFilters'];
      /* Grab projects based on active filters */
      await this.$store.dispatch('usersStore/fetch', filters);

      this.$store.dispatch('progressStore/set',false)
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
    },
    
    clearFilterColumns() {
      this.trainee_id = '',
      this.trainee_name_first = '',
      this.trainee_name_last = '',
      this.email = '',
      this.page = 1;
    },

	  async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
		  this.initialize();
    },
    
    changeSectionCheck() {
      if(this.section !== this.sectionStored) {
        this.clearFilterColumns();
        this.$store.dispatch('usersStore/clearTrainees');
      }
    },

	  async openEntry(id) {
		  await this.$store.dispatch('usersStore/entry', id);
    },

    async openDetail(id) {
      this.$store.dispatch('progressStore/set',true)

      await this.$store.dispatch('usersStore/read', id);
      
      this.$router.push({name: 'userDetail', params: { id: id } });
    },

    async deleteEntry(id) {
      await this.$store.dispatch('usersStore/read', id);
      this.deleteDialog = true;
    },

    async deleteRecord() {
      const record = this.$store.getters['usersStore/getUser'];

      const data = {
        id: record.id
      };

      await this.$store.dispatch('usersStore/delete', data);
      this.deleteDialog = false;
    },

    cancelDelete() {
      this.deleteDialog = false;
    },
    
    showFiltered(){
      if(this.trainee_id || this.trainee_name_first || this.trainee_name_last || this.email) {
        return true;
      }
    },
  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
